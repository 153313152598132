import { BrowserRouter,Routes,Route } from 'react-router-dom';

import './App.css';
import Home from './components/Home';
import About from './components/About';
import MainLayout from './layouts/MainLayout';
import New from './components/New'
import Agreement from './components/Agreement';
import ListsInstr from './videoinstr/ListsInstr';
import Tarif from './tarif/Tarif';


function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Routes>
    <Route element={<MainLayout>
      
    </MainLayout>} path='/'>
      <Route index element={<Home />}/>
      <Route element={<New />} path='news' />
      <Route element={<ListsInstr />} path='instr' />
      <Route element={<Tarif />} path='tarif' />
      <Route element={<Agreement />} path='agreement' />
<Route element={<About />} path='about'  />
    <Route element={<h1>Not Found</h1>} path='*' />
      

    </Route>
    
    </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
