import styles from '../cssmodules/Menu.module.css'

import { NavLink } from "react-router-dom"

const Menu = () => {
  return (
    <>
    <nav className={styles.item}>
      <NavLink to='.' end>Головна</NavLink>
      <NavLink to='news'>Новини</NavLink>
      <NavLink to='instr'>Інструкції</NavLink>
      <NavLink to='tarif'>Тарифи</NavLink>
      <NavLink to='agreement'>Договір</NavLink>
    <NavLink to='about'>Про нас</NavLink>
    
    </nav>
    </>
  )
}

export default Menu